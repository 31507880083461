<div class="other-page">
    <div class="flex column container" style="max-width: 1000px">
       <div class="flex position-center other-page-heading">Privacy Policy</div>
       <div class="content">
        <h3>ACCESSIBILITY</h3>

<p>drippz.in. is committed to ensuring a positive browsing experience on its website for all customers, including customers with disabilities. drippz.in utilises a range of tools in order to monitor and improve the accessibility of drippz.in website utilising the Web Content Accessibility Guidelines as a guide. drippz.in is committed to making its site even more accessible for users with disabilities.</p>

<h3>WEBSITE PRIVACY POLICY</h3>

<h4>Introduction</h4>

<p>This is the privacy policy for www.drippz.in being the e-commerce website of drippz.in (we, us and our) for the sale and purchase of our products (Site).&nbsp;<br />
We are committed to protecting your privacy on-line when visiting the Site or communicating electronically with us. We appreciate that you do not want the personal information you provide to us distributed indiscriminately and here we explain how and why we collect and process personal data, what we do with it and what controls you have. &nbsp;We will also treat all your personal information as confidential, l keep it on secure servers, and comply with all applicable Data Protection Law and consumer legislation.<br />
By using the Site, you acknowledge that you have read the terms of this privacy and cookies policy.<br />
Whose personal data do we process?<br />
We may process personal data relating to you if:</p>

<p>You are a user of our Site www.drippz.in.<br />
You are our customer of our Site www.drippz.in.<br />
You use our products from our Site www.drippz.in.</p>

<h3>Information we may collect from you</h3>

<p>Through your use of the Site www.drippz.in we may collect and process the following information about you:<br />
information (such as but not limited to your name, email address, billing address, delivery address, telephone number, product selections, payment type and card expiry number or log in and password details) that you provide by completing forms on the Site www.drippz.in, including if you register as a user of the Site, purchase any goods from the Site, upload or submit any material via the Site, request any information, or enter into any competition or promotion we may sponsor;</p>

<p>Information relating to transactions with us involving details of goods that we have supplied to you.</p>

<p>Information provided to us when you communicate with us, for example by email or phone. This includes communications you send to us, for example to report a problem or to submit queries, concerns or comments regarding the Site or its content.<br />
Information from surveys that we may, from time to time, run on the Site for research purposes, if you choose to respond to, or participate in, them.</p>

<p>Internet pages you have visited immediately before coming to the Site www.drippz.in or pages you visit immediately after leaving the Site.<br />
You are under no obligation to provide any such information. However, if you should choose to withhold requested information (e.g. your address or payment details), we may not be able to provide you with certain services. Manner for you and for your computer.</p>

<h3>Information we collect about you</h3>

<p>We will use this information:<br />
to administer our Site and your account with us under our terms and for internal operations, including identifying visitors to the Site, troubleshooting, and, in our legitimate interests, auditing the downloading of data, data analysis (demographic and sales data), testing, research, statistical and survey purposes;<br />
to improve our Site www.drippz.in to ensure that content is presented in the most effective manner for you and for your computer;<br />
to identify visitors to the Site; www.drippz.in<br />
to allow you to participate in interactive features of our Site, when you choose to do so;<br />
as part of our legitimate efforts to keep our Site safe and secure;<br />
for our legitimate interest of measuring or understanding the effectiveness of advertising we serve to you and others, and to deliver relevant advertising to you;<br />
to make suggestions and recommendations to you and other users of our Site about goods or services that may interest you or them in our legitimate interests and subject to your stated preferences where relevant.<br />
send you information we think you may find useful or which you have requested from us, including information about our products and services, provided you have indicated that you do not object to being contacted for these purposes;<br />
&nbsp;<br />
Allow, with your consent, carefully selected third parties to send you information directly which you may find useful regarding their products and services.<br />
&nbsp;<br />
You can tell us not to contact you with information regarding our products and services &nbsp;either at the point such information is collected on the Site (by checking (as directed) the relevant box) or, where you do not wish us to continue to use your information in this way, by following the unsubscribe instructions on any communications sent to you. You can also exercise the right at any time by contacting us details at the end of this privacy policy.<br />
Legal grounds for our processing of your personal data<br />
The basis on which we process your personal data is as follows:</p>

<p>Where it is necessary to obtain your prior consent to the processing concerned in order for us to be allowed to do it, we will obtain and rely on your consent in relation to the processing concerned.<br />
Otherwise, we will process your personal data only where the processing is necessary:<br />
for the performance of a contract to which you are a party or in order to take steps at your request prior to entering into such a contract;<br />
for compliance with a legal obligation to which we are a subject; or<br />
for the purposes of the legitimate interests pursued by us or another person, provided that this will only be in circumstances in which those legitimate interests are not overridden by your interests or fundamental rights and freedoms which require protection of personal data.<br />
Information sharing<br />
We may disclose aggregate statistics about visitors to the Site www.drippz.in, customers and sales in order to describe our services to prospective partners, advertisers, sponsors and other reputable third parties and for other lawful purposes, but these statistics will include no personally identifiable information.</p>

<p>We may disclose your personal information to any of our affiliates or Group Companies or to our agents or contractors who assist us in providing the services we offer through the Site, processing transactions, fulfilling requests for information, receiving and sending communications, updating marketing lists, analysing data, providing support services or in other tasks, from time to time.<br />
We may share your information with other companies and organisations for identity verification and fraud protection purposes to the extent permitted by applicable laws.</p>

<p>In the event that we undergo re-organisation or are sold to a third party, you agree that any personal information we hold about you may be transferred to that re-organised entity or third party.</p>

<p>You should be aware that we may be under a duty to disclose or share your personal information and/or user information in order to comply with any legal obligation. We may also disclose your personal information if we believe that such action is necessary to prevent fraud or cyber crime or to protect the Site or the rights, property or personal safety of any person.</p>

<h3>Child safety</h3>

<p>Protecting the safety of children when they use the Internet is very important to us. We recommend that children receive permission from their parent or guardian before gaining access to the Site or sending personal information to us or anyone else online.</p>

<h3>External links</h3>

<p>The Site www.drippz.in may, from time to time, contain links to external sites. Please note that other websites have their own privacy policies and processes and that we do not accept any responsibility or liability for these policies and processes. Please check these policies before you submit any personal data to these websites.</p>

<h3>Payment processing</h3>

<p>Payment details you provide will be encrypted using secure sockets layer (SSL) technology before they are submitted to us over the internet. Payments made on the Site are made through our payment gateway provider from time to time, [Paytm, BHIM UPI and Razorpay]. You will be providing credit or debit card information directly to such payment provider which operates a secure server to process payment details, encrypting your credit/debit card information and authorising payment. Information which you supply to such payment providers is not within our control and will be subject to their own privacy policy and terms and conditions. For more information please contact us.</p>

<h3>Security</h3>

<p>We place great importance on the security of all personally identifiable information associated with our users. We have security measures in place to attempt to protect against the loss, misuse and alteration of personal information under our control. For example, our security and privacy policies are periodically reviewed and enhanced as necessary and only authorised personnel have access to personal information. Whilst we cannot ensure or guarantee that loss, misuse or alteration of information will never occur, we use all reasonable efforts to prevent it.</p>

<p>You should bear in mind that submission of information over the internet is never entirely secure. We cannot guarantee the security of information you submit via the Site whilst it is in transit over the internet and any such submission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.</p>

<p>It is advisable to close your browser when you have finished your user session to help ensure others do not access your personal information if you use a shared computer or a computer in a public place.</p>

<h3>Data Retention</h3>

<p>We process personal data only for so long as it is necessary for the purpose(s) for which it was originally collected, after which it will be deleted or archived except to the extent that it is necessary for us to continue to process it for the purpose of compliance with legal obligations to which we are subject or for another legitimate and lawful purpose.<br />
In particular, the data relating to purchases will be kept for years .data collected for marketing purposes will be kept until your consent is revoked or until the newsletter service is active.<br />
Furthermore, the data collected may be stored to allow the company to defend itself in court. In such cases, only the data necessary to ensure full rights of defence will be stored. With regard to data collected through cookies please refer to the cookie policy.</p>

<p>You have the following rights in relation to personal data relating to you that we process:<br />
You may request that any incorrect personal data about you that we are processing be rectified.<br />
In certain circumstances (normally where the personal data has been provided by you and it is no longer necessary for us to continue to process it), you may be entitled to request that we erase the personal data concerned.<br />
Where we are processing personal data relating to you on the basis of your prior consent to that processing, you may withdraw your consent at any time, after which we shall stop the processing concerned. If you do withdraw your consent here, please note that this may affect our ability to provide you with our goods or services.<br />
If you have a complaint about any processing of your personal data being conducted by us, you can contact us or lodge a formal compliant with the Supervisory Authority<br />
</p>
<h3>Contacting us</h3>
<p>
Please submit any questions, concerns or comments you have about this privacy policy or any requests concerning your personal data by email to contact@drippz.in &nbsp;between the hours of 9am to 6pm, Monday to Friday or write to us at:<br />
Panshila,Deulia,Kolaghat,Purba Medinipur,721154,West Bengal,India.<br />
The Supervisory Authority &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<br />
The Indian Data Protection Authority is the supervisory authority of drippz.in and can provide further information about your rights and our obligations in relation to your personal data, as well as deal with any complaints that you have about our processing of your personal data.<br />
Changes to this policy&nbsp;<br />
Any changes we make to our privacy policy in the future will be posted on this page and, where appropriate, notified to you by e-mail. Please check back frequently to see any updates or changes to our privacy and cookies policy.</p>

<p><br />
&nbsp;Date of this policy<br />
&nbsp;This policy was last updated on 20/07/2023.<br />
&nbsp;</p>

<p>&nbsp;</p>

       </div>
    </div>
</div>