<footer class="footer">
  <div class="container">
    <div class="flex flex-column footer-inner">
      <div class="flex flex-wrap footer-top">
        <div class="flex column footer-top-column about">
          <div class="flex footer-section-heading">ABOUT</div>
          <ul class="flex column">
            <li><a href="/about-us">About Us</a></li>
            <li><a href="/contact-us">Contact Us</a></li>
            <li><a href="/faq">FAQ</a></li>
            <li><a href="https://drippz.in/blog" target="_blank">Blog</a></li>
          </ul>
        </div>
        <div class="flex column footer-top-column more">
          <div class="footer-section-heading">More Info</div>
          <ul class="flex column">
            <li><a href="/privacy-policy">Privacy Policy</a></li>
            <li><a href="/cookie-policy">Cookie Policy</a></li>
            <li> <a href="/order-payment-policy">Orders & Payment Policy</a></li>
            <li><a href="/shipping-return-policy">Shipping & Return Policy</a></li>
          </ul>
        </div>
        <div class="flex column text-center footer-top-column social">
          <div class="flex footer-section-heading">Reach out to us</div>
          <div class="flex column contact-box">
              <div class="flex allign-middle item">
                  <svg viewBox="0 0 512 512"><rect x="48" y="96" width="416" height="320" rx="40" ry="40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M112 160l144 112 144-112"/></svg>
                  <a href="mailto:support@drippz.in">support&#64;drippz.in</a>
              </div>
              <div class="flex allign-middle item">
                  <svg viewBox="0 0 512 512"><path d="M451 374c-15.88-16-54.34-39.35-73-48.76-24.3-12.24-26.3-13.24-45.4.95-12.74 9.47-21.21 17.93-36.12 14.75s-47.31-21.11-75.68-49.39-47.34-61.62-50.53-76.48 5.41-23.23 14.79-36c13.22-18 12.22-21 .92-45.3-8.81-18.9-32.84-57-48.9-72.8C119.9 44 119.9 47 108.83 51.6A160.15 160.15 0 0083 65.37C67 76 58.12 84.83 51.91 98.1s-9 44.38 23.07 102.64 54.57 88.05 101.14 134.49S258.5 406.64 310.85 436c64.76 36.27 89.6 29.2 102.91 23s22.18-15 32.83-31a159.09 159.09 0 0013.8-25.8C465 391.17 468 391.17 451 374z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/></svg>
                  <a href="tel:+91-760210752">+91-760210752</a>
              </div>
              <div class="flex allign-middle item">
                      <svg viewBox="0 0 512 512"><path d="M256 64C150 64 64 150 64 256s86 192 192 192 192-86 192-192S362 64 256 64z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M256 128v144h96"/></svg>
                  <span>10am to 6pm Mon - Sat</span>
              </div>
          </div>
          
          <p class="flex follow-text">Fllow us on social media</p>
          <div class="flex flex-wrap footer-social-box">
            <a class="flex" href="https://www.instagram.com/drippz.in/">
                <svg viewBox="0 0 512 512"><path fill="currentColor" d="M349.33 69.33a93.62 93.62 0 0193.34 93.34v186.66a93.62 93.62 0 01-93.34 93.34H162.67a93.62 93.62 0 01-93.34-93.34V162.67a93.62 93.62 0 0193.34-93.34h186.66m0-37.33H162.67C90.8 32 32 90.8 32 162.67v186.66C32 421.2 90.8 480 162.67 480h186.66C421.2 480 480 421.2 480 349.33V162.67C480 90.8 421.2 32 349.33 32z"/><path fill="currentColor" d="M377.33 162.67a28 28 0 1128-28 27.94 27.94 0 01-28 28zM256 181.33A74.67 74.67 0 11181.33 256 74.75 74.75 0 01256 181.33m0-37.33a112 112 0 10112 112 112 112 0 00-112-112z"/></svg>
            </a>
            <a class="flex" href="https://www.facebook.com/drippz.in/">
                <svg viewBox="0 0 512 512"><path fill="currentColor" d="M480 257.35c0-123.7-100.3-224-224-224s-224 100.3-224 224c0 111.8 81.9 204.47 189 221.29V322.12h-56.89v-64.77H221V208c0-56.13 33.45-87.16 84.61-87.16 24.51 0 50.15 4.38 50.15 4.38v55.13H327.5c-27.81 0-36.51 17.26-36.51 35v42h62.12l-9.92 64.77H291v156.54c107.1-16.81 189-109.48 189-221.31z" fill-rule="evenodd"/></svg>
            </a>
            <a class="flex" href="https://www.youtube.com/@drippz_in">
                <svg viewBox="0 0 512 512"><path fill="currentColor" d="M508.64 148.79c0-45-33.1-81.2-74-81.2C379.24 65 322.74 64 265 64h-18c-57.6 0-114.2 1-169.6 3.6C36.6 67.6 3.5 104 3.5 149 1 184.59-.06 220.19 0 255.79q-.15 53.4 3.4 106.9c0 45 33.1 81.5 73.9 81.5 58.2 2.7 117.9 3.9 178.6 3.8q91.2.3 178.6-3.8c40.9 0 74-36.5 74-81.5 2.4-35.7 3.5-71.3 3.4-107q.34-53.4-3.26-106.9zM207 353.89v-196.5l145 98.2z"/></svg>
            </a>
            <a class="flex" href="https://x.com/drippz_in">
              <svg x="0px" y="0px" viewBox="0 0 1668.56 1221.19" style="enable-background:new 0 0 1668.56 1221.19;" xml:space="preserve">
                <g transform="translate(52.390088,-25.058597)">
                  <path fill="currentColor" d="M283.94,167.31l386.39,516.64L281.5,1104h87.51l340.42-367.76L984.48,1104h297.8L874.15,558.3l361.92-390.99
                    h-87.51l-313.51,338.7l-253.31-338.7H283.94z M412.63,231.77h136.81l604.13,807.76h-136.81L412.63,231.77z"/>
                </g>
              </svg>
            </a>
            <a class="flex" href="https://in.pinterest.com/drippz_in/">
              <svg viewBox="0 0 512 512"><path fill="currentColor" d="M256.05 32c-123.7 0-224 100.3-224 224 0 91.7 55.2 170.5 134.1 205.2-.6-15.6-.1-34.4 3.9-51.4 4.3-18.2 28.8-122.1 28.8-122.1s-7.2-14.3-7.2-35.4c0-33.2 19.2-58 43.2-58 20.4 0 30.2 15.3 30.2 33.6 0 20.5-13.1 51.1-19.8 79.5-5.6 23.8 11.9 43.1 35.4 43.1 42.4 0 71-54.5 71-119.1 0-49.1-33.1-85.8-93.2-85.8-67.9 0-110.3 50.7-110.3 107.3 0 19.5 5.8 33.3 14.8 43.9 4.1 4.9 4.7 6.9 3.2 12.5-1.1 4.1-3.5 14-4.6 18-1.5 5.7-6.1 7.7-11.2 5.6-31.3-12.8-45.9-47-45.9-85.6 0-63.6 53.7-139.9 160.1-139.9 85.5 0 141.8 61.9 141.8 128.3 0 87.9-48.9 153.5-120.9 153.5-24.2 0-46.9-13.1-54.7-27.9 0 0-13 51.6-15.8 61.6-4.7 17.3-14 34.5-22.5 48a225.13 225.13 0 0063.5 9.2c123.7 0 224-100.3 224-224S379.75 32 256.05 32z"/></svg>
            </a>
            <a class="flex" href="https://www.linkedin.com/company/drippz/posts/?feedView=all">
              <svg viewBox="0 0 512 512"><path fill="currentColor" d="M444.17 32H70.28C49.85 32 32 46.7 32 66.89v374.72C32 461.91 49.85 480 70.28 480h373.78c20.54 0 35.94-18.21 35.94-38.39V66.89C480.12 46.7 464.6 32 444.17 32zm-273.3 373.43h-64.18V205.88h64.18zM141 175.54h-.46c-20.54 0-33.84-15.29-33.84-34.43 0-19.49 13.65-34.42 34.65-34.42s33.85 14.82 34.31 34.42c-.01 19.14-13.31 34.43-34.66 34.43zm264.43 229.89h-64.18V296.32c0-26.14-9.34-44-32.56-44-17.74 0-28.24 12-32.91 23.69-1.75 4.2-2.22 9.92-2.22 15.76v113.66h-64.18V205.88h64.18v27.77c9.34-13.3 23.93-32.44 57.88-32.44 42.13 0 74 27.77 74 87.64z"/></svg>
            </a>
          </div>



        </div>
        <div class="flex column text-center footer-top-column subscribe">
          <div class="footer-section-heading">STAY CONNECTED ALWAYS</div>
          <div class="footer-section-subheading">Sign up for exclusive offer , sales and new arrivals</div>
          <div class="flex position-center w-100 footer-email">
            <input placeholder="Enter your email..">
            <button class="flex position-center footer-subscribe-button">SUBSCRIBE</button>
          </div>
        </div>
      </div>
      <div class="flex column footer-company">
        <div class="footer-section-heading">Drippz - Men and Women Fashion Clothing</div>
        <div class="footer-company-description">
          Shop from Drippz, branded online shopping site for clothing. Choose the best Men and Women fashion wear. Free shipping, easy returns and COD is available!
          Shop from Drippz, branded online shopping site for clothing. Choose the best Men and Women fashion wear. Free shipping, easy returns and COD is available!
          Shop from Drippz, branded online shopping site for clothing. Choose the best Men and Women fashion wear. Free shipping, easy returns and COD is available!
          </div>
      </div>
      <div class="flex flex-column footer-popular-search">
        <div class="flex allign-middle footer-section-heading">Popular Searches:</div>
        <div class="flex flex-wrap pop-ser-links">
          <a class="flex allign-middle">New Arrivals</a>,
          <a class="flex allign-middle">Western Clothing</a>,
          <a class="flex allign-middle">Tops</a>,
          <a class="flex allign-middle">Skirts</a>,
          <a class="flex allign-middle">Denim</a>,
          <a class="flex allign-middle">Shurgs</a>
          <a class="flex allign-middle">Curve</a>,
          <a class="flex allign-middle">Accessories</a>,
          <a class="flex allign-middle">New Arrivals</a>,
          <a class="flex allign-middle">Western Clothing</a>,
          <a class="flex allign-middle">Tops</a>,
          <a class="flex allign-middle">Skirts</a>,
          <a class="flex allign-middle">Denim</a>,
          <a class="flex allign-middle">New Arrivals</a>,
          <a class="flex allign-middle">Western Clothing</a>,
          <a class="flex allign-middle">Tops</a>,
          <a class="flex allign-middle">Skirts</a>,
          <a class="flex allign-middle">Denim</a>,
          <a class="flex allign-middle">Shurgs</a>,
          <a class="flex allign-middle">Curve</a>,
          <a class="flex allign-middle">Accessories</a>,
          <a class="flex allign-middle">New Arrivals</a>,
          <a class="flex allign-middle">Western Clothing</a>,
          <a class="flex allign-middle">Tops</a>,
          <a class="flex allign-middle">Skirts</a>,
          <a class="flex allign-middle">Denim</a>,
          <a class="flex allign-middle">New Arrivals</a>,
          <a class="flex allign-middle">Western Clothing</a>,
          <a class="flex allign-middle">Tops</a>,
          <a class="flex allign-middle">Skirts</a>,
          <a class="flex allign-middle">Denim</a>,
          <a class="flex allign-middle">Shurgs</a>,
          <a class="flex allign-middle">Curve</a>,
          <a class="flex allign-middle">Accessories</a>,
          <a class="flex allign-middle">New Arrivals</a>,
          <a class="flex allign-middle">Western Clothing</a>,
          <a class="flex allign-middle">Tops</a>,
          <a class="flex allign-middle">Skirts</a>,
          <a class="flex allign-middle">Denim</a>,
          <a class="flex allign-middle">New Arrivals</a>,
          <a class="flex allign-middle">Western Clothing</a>,
          <a class="flex allign-middle">Tops</a>,
          <a class="flex allign-middle">Skirts</a>,
          <a class="flex allign-middle">Denim</a>,
          <a class="flex allign-middle">Shurgs</a>,
          <a class="flex allign-middle">Curve</a>,
          <a class="flex allign-middle">Accessories</a>,
          <a class="flex allign-middle">New Arrivals</a>,
          <a class="flex allign-middle">Western Clothing</a>,
          <a class="flex allign-middle">Tops</a>,
          <a class="flex allign-middle">Skirts</a>,
          <a class="flex allign-middle">Denim</a>,
        </div>
      </div>
      <div class="flex allign-middle justify-content-between footer-bottom">
        <div class="flex allign-middle footer-disclaimer">
         <p>©2023 Orange Essentials Enterprise,
            All Rights Reserved.
          </p>
        </div>
        <div class="flex column payment-partners">
          <img src="../assets/branding/secure-payments-icon.png" alt="100% Secure Payment">
        </div>
      </div>
    </div>
  </div>
</footer>