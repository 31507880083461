import { Component, OnInit } from '@angular/core';
import { GlobalsService } from '../../services/globals.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  wholesale = {
    name: '',
    email: '',
    phone: '',
    address: '',
    pieces: '',
    text: '',
    captcha: '',
  };
  errors = {} as any;
  popup_state = false;
  popup_text = '';
  customer_key = '';
  popup_type = '';
  siteKey = '6LcaCAYiAAAAAPEG5QaOglJRQCf05E69_i_Auvsi';
  //siteKey = '6LeSIFgpAAAAAGjnetfYiu3Xb3ibNOH55xDIXMSG';

  constructor(
    public globals: GlobalsService,
    private httpClient: HttpClient,
    private router: Router,
    private activeRoute: ActivatedRoute,
    public toastr: ToastrService,
    private auth: AuthenticationService,
  ) { }

  ngOnInit(): void {
  }

  async ngAfterViewInit() {
    this.customer_key = atob(await this.globals.get_customer_key());
  }

  ngAfterContentInit() {
  }

  validate() {
    let customer = this.auth.customer;
    this.errors = {} as any;
    const preg_email = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (this.wholesale.name.length == 0) {
      this.errors.name = "Please enter your name";
    }

    if (this.wholesale.email.length == 0) {
      this.errors.email = "Please enter your email";
    }
    if (this.wholesale.email.length > 0 && !preg_email.test(this.wholesale.email)) {
      this.errors.email = "Invalid email";
    }

    if (this.wholesale.phone.length == 0) {
      this.errors.phone = "Please enter your mobile/contact no";
    }
    if (this.wholesale.phone.length > 0 && this.wholesale.phone.length < 10) {
      this.errors.phone = "Invalid mobile no. Min. 10 digits.";
    }

    if (this.wholesale.captcha.length == 0) {
      this.errors.captcha = "Please select google captcha";
    }

    return (Object.entries(this.errors).length == 0) ? true : false;
  }

  async save() {
    if (this.validate()) {
      let action = 'query/save';

      if (this.popup_type == 'feedback') {
        action = 'feedback/save';
      }

      let postData = {
        action: action,
        name: this.wholesale.name,
        email: this.wholesale.email,
        contact: this.wholesale.phone,
        address: this.wholesale.address,
        pieces: this.wholesale.pieces,
        text: this.wholesale.text,
        captcha: this.wholesale.captcha,
        type: '',
      };
      this.globals.set_page_loader(false);

      let results = this.httpClient.post<any>(this.globals.get_endpoint() + `/${action}`, postData)
        .subscribe((response) => {
          if (response.msg == 'success') {
            this.toastr.clear();
            this.toastr.success("Your request sumbitted successfully. We will get back to you soon.", '', { positionClass: 'toast-bottom-center' });

            this.reset();
            this.hide_popup();
            this.globals.set_page_loader(false);
          } else {
            this.globals.set_page_loader(false);
          }
        });
    }
  }

  remove_error(name: string) {
    let err = this.errors;
    delete err[name];

    var preg = /^[0-9 ]+$/;
    var regx = /[^0-9]+/g;

    if (name == 'phone') {
      let phone = this.wholesale.phone;

      if (this.wholesale.phone.length > 0 && !preg.test(this.wholesale.phone)) {
        err.phone = 'Invalid contact number';
        phone = phone.replace(regx, '');
        this.wholesale.phone = phone;
        //console.log([phone, this.address]);
      }
      if (phone.length > 10) {
        phone = phone.substring(0, 10);
        this.wholesale.phone = phone;
      }
    }

    if (name == 'pieces') {
      let pieces = this.wholesale.phone;

      if (this.wholesale.pieces.length > 0 && !preg.test(this.wholesale.pieces)) {
        err.pieces = 'Invalid quanty';
        pieces = pieces.replace(regx, '');
        this.wholesale.pieces = pieces;
        //console.log([pincode, this.address]);
      }
    }

    this.errors = err;
  }

  check_enter_pressed(event: any) {
    ////console.log([name, event]);
    if (event.keyCode == 13) {
      this.save();
    }
  }

  reset() {
    this.wholesale.name = '';
    this.wholesale.email = '';
    this.wholesale.phone = '';
    this.wholesale.address = '';
    this.wholesale.pieces = '';
    this.wholesale.text = '';
  }

  show_popup(name: string) {
    this.popup_type = name;
    this.popup_state = true;
  }

  hide_popup() {
    this.popup_type = '';
    this.popup_state = false;
  }

  //--google captcha---
  handleReset() { }
  handleExpire() { }
  handleLoad() { }
  handleSuccess($event: any) {
    this.wholesale.captcha = $event;
    console.log($event);
  }

  validate_password(password: string) {
    let pregUpper = /[A-Z]/;
    let pregNumber = /[0-9]/;
    let pregNumberSequence = /^.*?(?:012|123|234|345|456|567|678|789|987|876|765|654|543|432|321|210|([0-9])\1{2}).*$/;
    let pregSpcl = /[!@#$%^&*]/;
    //let email = $('[name=email]').val();
    let msg = "";

    if ((password != '') && (!pregUpper.test(password))) {
      msg += "Must contain atleast one Capital letter\n";
    }
    if ((password != '') && (!pregNumber.test(password))) {
      msg += "Must contain atleast one Number \n";
    }
    if ((password != '') && (pregNumberSequence.test(password))) {
      msg += "Shouldn't contain numbers in sequence \n";
    }
    if ((password != '') && (!pregSpcl.test(password))) {
      msg += "Must contain atleast one Special Character (!@#$%^&*) \n";
    }

    if ((password != '') && ((password.length < 8) || (password.length > 20))) {
      msg += "Must be 8 - 20 characters long \n";
    }
    /*if ((password != '') && (password == email)) {
      msg += "Must not be same as Email Address \n";
    }*/
  }

}
