<div class="other-page">
    <div class="flex column container" style="max-width: 1000px">
       <div class="flex position-center other-page-heading">SHIPPING & RETURN POLICY</div>
       <div class="content">
            <h3>SHIPPING METHODS</h3>

            <p>www.drippz.in delivers parcels all over the world via 
                DTDC.DELHIVERY,ECOM EXPRESS,BLUE DART EXPRESS,INDIA POST.
                All orders are processed within 2-3 working day. However, during sale seasons orders might suffer a slight delay due to an excessive amount of orders being filled out. www. drippz.in  will not be held responsible for a delay in shipment caused by weather conditions, international customs issues or any other circumstances beyond the control of drippz.in
                All shipments are accompanied with an official invoice with the exact declared value of each item in rupees. Sale and discounted items reflect discounted prices. Worth of goods will be publicly available on the package’s insert for tax reasons on all orders. Once orders are sent they can no longer be cancelled and change of delivery address is no longer possible, please make sure to fill out a correct address when checking out. Once you receive your parcel, you can ask for the return or exchange within 7 days you (or someone you nominate, other than a carrier) received the goods.
                </p>

            <h3>SHIPPING INFORMATION</h3>

            <p>www.drippz.in delivers parcels all over the world via 
                DTDC.DELHIVERY,ECOM EXPRESS,BLUE DART EXPRESS,INDIA POST.
                All orders are processed within 2-3 working day. However, during sale seasons orders might suffer a slight delay due to an excessive amount of orders being filled out. www. drippz.in  will not be held responsible for a delay in shipment caused by weather conditions, international customs issues or any other circumstances beyond the control of drippz.in
                <br />
                All shipments are accompanied with an official invoice with the exact declared value of each item in rupees. Sale and discounted items reflect discounted prices. Worth of goods will be publicly available on the package’s insert for tax reasons on all orders. Once orders are sent they can no longer be cancelled and change of delivery address is no longer possible, please make sure to fill out a correct address when checking out. Once you receive your 
                parcel, you can ask for the return or exchange within 7 days you (or someone you nominate, other than a carrier) received the goods.</p>


            <h3>SHIPMENT TRACKING</h3>

            <p>When the order will be tendered to the courier, customers receive an email from the courier providing the tracking number (AWB) related to the order.
                 To monitor the status of your order, customers may visit the courier website and insert the AWB number in the “track your shipment” area”.</p>

           <h3>DELIVERY TIME</h3>

            <p>drippz.in   processes orders in India, West Bengal from Monday to Saturday.<br />
            www.drippz.in   processes orders within 48 - 72 hours of the order’s placement, or the next available working day. Orders placed from Friday to Sunday, or holiday will be processed the following business day.<br />
            Minimum delivery time 3 - 5 days and maximum delivery time 7 - 10 day depending on the addresses and the circumstances.<br />
            www.drippz.in   cannot be accountable for carrier delays or delays caused by holidays, meteorological conditions and other circumstances that are irrelevant to www.drippz.in   control.<br />
            Once shipped, arrival time of packages may vary from city to city.</p>

            <h3>TERMS AND CONDITIONS OF RETURN</h3>

            <p>For any reason, customers can return the order, entirely or partially. 
                drippz.in refunds within 7 days from the receipt of the merchandise (excludes certain products).<br/>
                Items must be returned in the same original conditions, neatly packed and with the return tag still attached.<br/>
                Please note that Customized items cannot be returned. Customers are responsible for ensuring that the customization details are correct. Neither cancellation nor alteration may be made once a customized order has been placed. It is not possible to request customization for items after an order has been placed or delivered.
                </p>

            <h3>T&C</h3>

            <p>The returned items must be sent back in their original packaging together with all the materials received with the item/s (bags, tag, bills, covers etc.) and should have a copy of the invoice inside.<br/>
                The item/s must be unharmed, unwashed, not worn or altered and must have all original security seals still attached. When trying on shoes, please be mindful not to scratch the soles or mark the shoe box. Briefs,
                 swimsuits and bikini bottoms should be tried on over underwear, without removing the protective adhesive strip or they will not be accepted. 
                This is for hygiene reasons. We will not accept any returns that have been worn or are soiled.<br/>
                The drippz.in  packaging must not be used as the external parcel for the shipment.<br/>
                If the item does not fit these requirements the item will not be accepted and will be sent back to the customer at their own cost.<br/>
                All products are checked at the warehouse for any faults. However, if a customer receives an item not in the original state please contact info@drippz.in <br/>
                Goods are classified as faulty if they are not of satisfactory quality, fit for purpose or as described. Please note that items which are damaged as a result of normal wear and tear; by accident; or through misuse will not be considered faulty. If your item is faulty when you receive it,
                 you can return it for a refund within 7 days from the date you received it.<br/>
                 We have made every effort to display the colors of our products that appear on Aarnava.com as accurately as possible. However, as computer monitors, 
                 tablets and mobile devices vary, we cannot guarantee that your monitor's display of a color will be completely accurate.
            </p>

            <h3>RETURNS POLICY</h3>

            <p>Please refer to our Return Policy which forms an integral part of these Terms and Conditions. <br/>
                Returned Products must conform to our returns policy below. You have a legal obligation to take reasonable care of the products while they are in your possession, and you must
                 return them in the same condition in which you receive them (except to the extent reasonably necessary to examine them). <br/>
                This includes the following guidelines:<br/>
                - Products should be returned unworn (other than to try them on), unwashed, undamaged and unused with their original tags;<br/>
                - footwear and accessories should be returned in the original boxes provided and inside a protective shipping box;<br/> 
                - if the Product comes with a security tag this should be left on;<br/>
                - hosiery should only be returned if it is unopened and is in its original package;<br/>
                - If you fail to comply with the above obligations (including the conditions of return), we may deduct from the 
                refund an amount to reflect the diminished value of the Product(s) up to the full price of the Product(s). 
                You cannot cancel a contract for the supply of any of the following Products: any Products that have been personalised or made to your own bespoke specifications unless such
                 Products were damaged or faulty when delivered to you or have been incorrectly delivered (see Returns and refunds for customised Products below for more information); 
                 earrings of any type including costume or fine jewellery (see Returns and refunds of jewellery below for more information); and any garments or cosmetics Products that have had a hygiene label or seal removed or broken.
                </p>

            <h3>Returns and refunds for customised Products </h3>

            <p>Due to the nature of personalised and monogrammed Products, returns, changes or cancellations are at our discretion. In exercising this discretion, we will have regard to the level of customisation and personalisation and also reserve the right to offer a store credit rather than a monetary refund. Store credit is valid for 6 months from the date of issue. This does not affect your statutory rights. Please note, that personalised and/or customised Products may have a long 
                lead in time before shipping, but payment will be taken at the time of or shortly after you submit your order and in advance of shipping.</p>

            <h3>RETURN PROCESS</h3>

            <p>We recommend that you return Products in their original packaging to ensure the necessary protection when in transit.<br/>
                Instructions for the return of the Product(s) will be included with the delivery package.<br/>
                When cancelling a purchase and returning Products to us you have two options available to you: <br/>
                1. Use our Free Returns service. If you choose this option you can return the Products(s) for free and will be refunded the full price for the Product(s) <br/>
                (subject to our Returns Policy) but not the delivery charges.  We describe these two options in more detail below. <br/>
            </p>

            <p>

                To cancel a Contract, you must clearly inform us, preferably: by email at info@drippz.in . <br/>
                If you cancel an order (or part of an order) during the period, you must return the Product(s) within 7 days after the day on which you notify us of the cancellation and comply with the Returns Policy.<br/>
                If you cancel a Contract between us within the 14 day cooling-off period (see above), we will process the refund due to you as soon as possible and,
                in any case within 14 days after the day on which we receive the Product(s) back or (ii) if earlier, the day on which we receive evidence that you have returned the Product(s) to our returns address. <br/>
                We will refund the price of the Product(s) in full (subject to any deduction we are entitled to make due to 
                your use of or damage to the Product(s)), including the cost of standard delivery. However, we will not refund your cost 
                of returning the Product(s) to us, and you are responsible for those costs. We will refund any money received from you using the same method originally used by you to pay for your purchase, unless agreed otherwise. 

            </p>
            <h3>Free Returns Pick Up </h3>
            <p>
                We offer a free collection service to all customers for Product(s) you wish to return except in relation to certain types of Products as set out in the Returns Policy. 
                You have 14 days from receiving your order to return the Product to us. We strongly recommend that you book your free returns pick-up within 7 days of receiving your order to ensure that it arrives back in time.<br/>
                Please note that we can only collect returns from the same address to which your order was delivered.<br/>
                To book a free returns pick-up:<br/>
                - sign in to the Site and go to My Account; <br/>
                - under 'Orders' click on the 'Book a return collection' link next to the order you want to return;<br/>
                - select the Products you would like to return and follow the steps to schedule a pick-up time and address.<br/>
                
                Our delivery partner will e-mail, whatsapp or text you with confirmation of your collection time and address, a booking reference number and returns documents.<br/>
                
                
                Do not seal your package until the courier person has checked the contents.<br/>
                Please note that you have 14 days to contact us upon the pickup of your return to ensure that we have acknowledged it and received the Product. If you contact us after this 14 days period, we cannot guarantee a refund.<br/>
                The exchange of purchased Products is not allowed. Therefore, Products received after purchase may only be returned and result in a refund of the price paid for the purchase under the conditions set out in these Terms and Conditions.
                
            </p>

            <p><br />
            &nbsp;Date of this policy<br />
            &nbsp;This policy was last updated on 20/07/2023.<br />
            &nbsp;</p>

            <p>&nbsp;</p>

                </div>
    </div>
</div>
