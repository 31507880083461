<app-header-announcement></app-header-announcement>
<div class="flex column header">
  <header class="flex allign-middle site-header">
    <div class="flex column w-100 site-header-box">
      <div class="flex position-center container">
        <div class="flex allign-middle header-item left h-100 navigation">
          <button class="flex site-nav" (click)="openMenu()">
            <svg viewBox="0 0 512 512">
              <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="48"
                d="M88 152h336M88 256h336M88 360h336" />
            </svg>
          </button>
          <a class="flex position-center header-links-items search" (click)="openSearch()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z" fill="none"
                stroke="currentColor" stroke-miterlimit="10" stroke-width="32" />
              <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32"
                d="M338.29 338.29L448 448" />
            </svg>
          </a>

          <!--<app-menu-top class="flex h-100 header-menu"></app-menu-top> -->
        </div>
        <div class="flex position-center header-item middle logo" style="cursor: pointer;">
          <a class="flex position-center" [routerLink]="['/']" routerLinkActive="router-link-active">
            <svg viewBox="0 0 834 196">
              <path fill="currentColor" d="M63.4,1c48.8,0,73.2,27,73.2,70.4v54.1c0,43.4-24.5,70.4-73.2,70.4H1.1V1H63.4z M41.1,158.1
                l27.7-0.3c22.8,0,27.7-14.1,27.7-34.9v-49c0-20.8-5-35.1-27.7-35.1l-27.7-0.1V158.1z"/>
              <path fill="currentColor" d="M295.6,195.8h-40.5l-25.9-62.8h-21.7v62.8h-40V1h60.8c43.9,0,67,20.8,67,59.4v14.9
                c0,25.1-10.1,42.2-30.1,51L295.6,195.8z M207.5,38.2v59.1l24,0.6c17.5,0,24.2-4.4,24.2-20.7V60.1c0-16.3-6.8-21.9-24.2-21.9
                L207.5,38.2z"/>
              <path fill="currentColor" d="M620,1c45.3,0,66.7,21.2,66.7,60.9v23c0,39.7-21.4,59.7-66.7,59.7h-19.4v51.2h-40V1H620z
                M648.2,64.1c0-16.9-6.5-25.2-25.7-25.2l-21.9-0.2v67.9l22.5-0.1c19.1,0,25.1-8,25.1-24.9L648.2,64.1z"/>
              <path fill="currentColor" d="M756.6,158.4h74.3v37.4H708.1v-37.4l69-112.9l3.8-7h-72.7V1h122.8v37.7l-70.4,114L756.6,158.4z"
                />
              <path fill="currentColor" d="M464.3,1C509.6,1,531,22.2,531,61.9v23c0,39.7-21.4,59.7-66.7,59.7h-19.4v51.2h-40V1H464.3z
                M492.5,64.1c0-16.9-6.5-25.2-25.7-25.2l-21.9-0.2v67.9l22.5-0.1c19.1,0,25.1-8,25.1-24.9L492.5,64.1z"/>
              <path fill="currentColor" d="M367.5,1.1L367.5,1.1L367.5,1.1V156c0,22.1-17.9,40-40,40c0,0,0,0,0,0l0,0l0,0V41.1
                C327.6,19,345.5,1.1,367.5,1.1C367.5,1.1,367.5,1.1,367.5,1.1z"/>
            </svg>
          </a>
        </div>

        <div class="flex header-item right header-links">
          <div class="flex allign-middle header-links-container">
            <!--<a class="flex position-center header-links-items search" (click)="openSearch()">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z" fill="none"
                  stroke="currentColor" stroke-miterlimit="10" stroke-width="32" />
                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32"
                  d="M338.29 338.29L448 448" />
              </svg>
            </a>-->

            <a class="flex position-center header-links-items account" (click)="openLsv()" *ngIf="!is_logged_in()">
              <svg viewBox="0 0 512 512">
                <path d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z" fill="none"
                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
                <path
                  d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
                  fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" />
              </svg>
            </a>


            <div class="flex position-center header-links-items account item user-account" *ngIf="is_logged_in()">
              <svg viewBox="0 0 512 512">
                <path d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z" fill="none"
                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
                <path
                  d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
                  fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" />
              </svg>
              <div class="flex flex-column user-account-menu">
                <div class="flex position-center not-loggedin">
                  <span>Welcome! {{customer_fname}}</span>
                </div>
                <div class="flex flex-column header-profile-link">
                  <a class="flex allign-middle" routerLink="/account/orders">
                    <svg viewBox="0 0 512 512" data-fa-i2svg="">
                      <path fill="currentColor"
                        d="M32 448c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32V160H32v288zm160-212c0-6.6 5.4-12 12-12h104c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-8zM480 32H32C14.3 32 0 46.3 0 64v48c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16V64c0-17.7-14.3-32-32-32z">
                      </path>
                    </svg>
                    <span>Orders</span>
                  </a>
                  <a class="flex allign-middle" routerLink="/account/wishlist">
                    <svg viewBox="0 0 512 512">
                      <path fill="currentColor"
                        d="M256 448a32 32 0 01-18-5.57c-78.59-53.35-112.62-89.93-131.39-112.8-40-48.75-59.15-98.8-58.61-153C48.63 114.52 98.46 64 159.08 64c44.08 0 74.61 24.83 92.39 45.51a6 6 0 009.06 0C278.31 88.81 308.84 64 352.92 64c60.62 0 110.45 50.52 111.08 112.64.54 54.21-18.63 104.26-58.61 153-18.77 22.87-52.8 59.45-131.39 112.8a32 32 0 01-18 5.56z" />
                    </svg>
                    <span>Wishlist</span>
                  </a>
                  <a class="flex allign-middle" routerLink="/contact-us">
                    <svg viewBox="0 0 512 512" data-fa-i2svg="">
                      <path fill="currentColor"
                        d="M32 448c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32V160H32v288zm160-212c0-6.6 5.4-12 12-12h104c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-8zM480 32H32C14.3 32 0 46.3 0 64v48c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16V64c0-17.7-14.3-32-32-32z">
                      </path>
                    </svg>
                    <span>Customer Care</span>
                  </a>
                </div>
                <div class="flex flex-column header-profile-logout">
                  <a class="flex allign-middle" (click)="logout()">
                    <svg viewBox="0 0 512 512">
                      <path fill="#000"
                        d="M256 464c-114.69 0-208-93.23-208-207.82a207.44 207.44 0 0174.76-160.13l16.9-14 28.17 33.72-16.9 14A163.72 163.72 0 0092 256.18c0 90.39 73.57 163.93 164 163.93s164-73.54 164-163.93a163.38 163.38 0 00-59.83-126.36l-17-14 28-33.82 17 14A207.13 207.13 0 01464 256.18C464 370.77 370.69 464 256 464z" />
                      <path fill="#000" d="M234 48h44v224h-44z" />
                    </svg>
                    <span>Logout</span>
                  </a>
                </div>
              </div>
            </div>







            <!--<div class="flex position-center item user-account">
              <a class="flex position-center header-links-items account">
                <svg viewBox="0 0 512 512">
                  <path d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z" fill="none"
                    stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
                  <path
                    d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
                    fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" />
                </svg>
              </a>
              <div class="flex flex-column user-account-menu">

                for logged in users
                <div class="flex flex-column header-profile-link" *ngIf="is_logged_in()">
                  <a class="flex allign-middle" routerLink="/account/orders">
                    <svg viewBox="0 0 512 512" data-fa-i2svg="">
                      <path fill="currentColor"
                        d="M32 448c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32V160H32v288zm160-212c0-6.6 5.4-12 12-12h104c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-8zM480 32H32C14.3 32 0 46.3 0 64v48c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16V64c0-17.7-14.3-32-32-32z">
                      </path>
                    </svg>
                    <span>Orders</span>
                  </a>
                  <a class="flex allign-middle" routerLink="/account/wishlist">
                    <svg viewBox="0 0 512 512">
                      <path fill="currentColor"
                        d="M256 448a32 32 0 01-18-5.57c-78.59-53.35-112.62-89.93-131.39-112.8-40-48.75-59.15-98.8-58.61-153C48.63 114.52 98.46 64 159.08 64c44.08 0 74.61 24.83 92.39 45.51a6 6 0 009.06 0C278.31 88.81 308.84 64 352.92 64c60.62 0 110.45 50.52 111.08 112.64.54 54.21-18.63 104.26-58.61 153-18.77 22.87-52.8 59.45-131.39 112.8a32 32 0 01-18 5.56z" />
                    </svg>
                    <span>Wishlist</span>
                  </a>
                  <a class="flex allign-middle" routerLink="/contact-us">
                    <svg viewBox="0 0 512 512" data-fa-i2svg="">
                      <path fill="currentColor"
                        d="M32 448c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32V160H32v288zm160-212c0-6.6 5.4-12 12-12h104c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-8zM480 32H32C14.3 32 0 46.3 0 64v48c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16V64c0-17.7-14.3-32-32-32z">
                      </path>
                    </svg>
                    <span>Customer Care</span>
                  </a>
                </div>
                ./for logged in users

                <div class="flex flex-column header-profile-logout" *ngIf="is_logged_in()">
                  <a class="flex allign-middle" (click)="logout()">
                    <svg viewBox="0 0 512 512">
                      <path fill="#000"
                        d="M256 464c-114.69 0-208-93.23-208-207.82a207.44 207.44 0 0174.76-160.13l16.9-14 28.17 33.72-16.9 14A163.72 163.72 0 0092 256.18c0 90.39 73.57 163.93 164 163.93s164-73.54 164-163.93a163.38 163.38 0 00-59.83-126.36l-17-14 28-33.82 17 14A207.13 207.13 0 01464 256.18C464 370.77 370.69 464 256 464z" />
                      <path fill="#000" d="M234 48h44v224h-44z" />
                    </svg>
                    <span>Logout</span>
                  </a>
                </div>
              </div>
            </div>-->

            <!--<a class="flex position-center header-links-items wishlist ">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                  d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z"
                  fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
              </svg>
            </a>-->
            <a class="flex position-center position-relative header-links-items" (click)="openCart()">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"
                  d="M80 176a16 16 0 00-16 16v216c0 30.24 25.76 56 56 56h272c30.24 0 56-24.51 56-54.75V192a16 16 0 00-16-16zM160 176v-32a96 96 0 0196-96h0a96 96 0 0196 96v32" />
              </svg>
              <span class="flex position-center badge" *ngIf="cart_count>0">{{cart_count}}</span>
            </a>
          </div>
        </div>
      </div>

      <div class="search-container">
        <div class="search-entry-box">
          <div class="search-icon"></div>
          <div class="search-input"></div>
          <div class="search-close"></div>
        </div>
      </div>

      <nav class="navbar" aria-label="Main Navigation">
        <ul class="flex position-center navbar-nav">
            <li class="nav-item mega-menu">
                <a class="nav-link" aria-current="page" href="#">Products</a>
                <div class="menu-box">
                    <div class="flex column content">
                        <a href="/category/graphic-t-shirt" class="menu-link">Graphic T-shirt</a>
                        <a href="/category/tie-dye-t-shirt" class="menu-link">Tie Dye T-shirt</a>
                        <a href="/category/oversize-t-shirt" class="menu-link">Oversize T-shirt</a>
                    </div>
                </div>
            </li>
            <li class="nav-item mega-menu">
                <a class="nav-link" aria-current="page" href="#">Collections</a>
                <div class="menu-box">
                    <div class="flex column content">
                        <a href="/category/shapes-of-thoughts" class="menu-link">Shapes of Thoughts</a>
                        <a href="/category/coloured-patches" class="menu-link">Coloured Patches</a>
                        <a href="/category/canva-drip" class="menu-link">Canva Drip</a>
                        <a href="/category/arbitrary-hues" class="menu-link">Arbitrary Hues</a>
                        <a href="/category/money" class="menu-link">Money</a>
                    </div>
                </div>
            </li>
            <li class="nav-item single-menu">
                <a class="nav-link" aria-current="page" href="/category/oversize-t-shirt">New In</a>
            </li>
            <li class="nav-item single-menu">
                <a class="nav-link" aria-current="page" href="/search">All Products</a>
            </li>
            <li class="nav-item single-menu">
                <a class="nav-link" aria-current="page" href="/contact-us">Contact us</a>
            </li>
        </ul>
      </nav>
    </div>
  </header>

</div>
