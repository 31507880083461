<div class="other-page">
    <div class="flex column container" style="max-width: 1000px">
       <div class="flex position-center other-page-heading">TERMS & CONDITIONS</div>
       <div class="content">
        <h3><strong>INTRODUCTION</strong></h3>

<p>These terms and conditions (terms and condition) govern your use of, access to, and purchase of products through www.drippz.in (the &ldquo;website&rdquo; or &quot;site&quot;). By using the site, you agree to comply with and be bound by these terms and conditions. If you do not agree to these terms and conditions, please do not use the site.</p>

<h3><strong>GENERAL</strong></h3>

<p>www.drippz.in is the property of ORANGE ESSENTIAL ENTERPRISE., (Registration Number 3448 (W.B), GST Number <strong>19DCKPM5197P1ZG</strong>), whose Registered office is at Payag,Kolaghat,Purba Medinipur,West Bengal,India.</p>

<h3><strong>OTHER SITE POLICIES</strong></h3>

<p>Please review our Terms &amp; Condition, Return Policy, Privacy and Cookies Policy (collectively, the &ldquo;Site Policies&quot;). All Site Policies are incorporated in these Terms and Conditions by this reference and, therefore, apply to your access to, use of and purchase of products from the Site (&ldquo;Product&rdquo;). If you do not agree to our Site Policies, please do not use the Site. We reserve the right to make changes to the Site, the Site Policies, and these Terms and Conditions at any time. If any of these conditions shall be deemed invalid, void, or for any reason unenforceable, that condition shall be deemed severable and shall not affect the validity and enforceability of any remaining&nbsp;</p>

<h3><strong>ACCESS TO THE SITE</strong></h3>

<p>It is your responsibility to ensure your equipment (computer, laptop, notebook, tablet or other mobile device) meets all the necessary technical specifications to enable you to access and use the Site and is compatible with the Site.<br />
We may, from time to time, restrict access to certain features, parts or content of the Site, or the entire Site, to users who have registered with us. You must ensure that any registration details you provide are accurate. If you choose, or you are provided with, a log-on ID (such as a username and password or other identifier) as part of our security procedures, you must treat such information as confidential and must not reveal it to anyone else. You are responsible for all activities that occur under your log-on ID and must notify us immediately of any unauthorised use or other security breach of which you become aware. We reserve the right to disable any log-on ID, at any time, if in our opinion you have failed to comply with any of the provisions of these terms and conditions or if any details you provide for the purposes of registering as a user proves to be false.</p>

<p><strong>PRODUCTS</strong></p>

<p>The products are owned and sold on the Website by Drippz ( ORANGE&nbsp; ESSENTIAL ENTERPRISE). Drippz attempts to be as accurate as possible in the description of the products. However, we cannot guarantee that any descriptions are totally accurate, complete, reliable or error-free. Please note that the images of the Products on the Website are for illustrative purposes only and while we have tried to accurately display the colors of products, the actual colors you see will depend on your monitor and may not be accurate.</p>

<h3><strong>PURCHASE FOR PERSONAL USE ONLY</strong></h3>

<p>You may purchase products on the Site only for personal use and not for resale. By placing your order, you certify that you are purchasing products for personal use only and not for resale and you accept our Terms and Conditions Neither Drippz nor the Provider has any liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.&nbsp;</p>

<h3><strong>ORDERING AND AVAILABILITY</strong></h3>

<p>Products may be ordered by clicking on the items you wish to purchase and then following the prompts that will appear on-screen. You may check and correct any input errors in your order up until the point at which you submit your order to us by clicking the &quot;Pay Now&quot; Or &ldquo;COD&rdquo; button on the checkout page. All orders are subject to email confirmation by us. After placing an order, you will receive an email from us acknowledging that we have received your order and giving you an order reference number. Please note that this does not mean that your order has been accepted. Your order constitutes an offer to us to buy a Product. All orders are subject to acceptance by us. We are not obliged to accept your order and May, at our discretion, decline to accept any order. You do, however, acknowledge that by clicking on the &quot;Pay Now&quot; Or &ldquo;COD&rdquo; button, you enter into an obligation to pay for the Products. Where we accept your order, we will confirm such acceptance to you by sending you an email that confirms that the Product has been dispatched (&ldquo;Dispatch Confirmation&rdquo;). The contract between you and us in relation to the Products ordered (&ldquo;Contract&rdquo;) will only be formed when we send you the Dispatch Confirmation. After entering into the Contract, we will be under a legal duty to supply you with goods that are in conformity with the Contract. The Contract will relate only to the Product(s) whose dispatch we have confirmed in the Dispatch Confirmation. We will not be obliged to supply any other Products which may have been part of your order until the dispatch of such Products has been confirmed in a separate Dispatch Confirmation.&nbsp;</p>

<p>Please note that the Products displayed on the Site may be out-of-stock or discontinued, and availability is not guaranteed.</p>

<h3><strong>SHIPPING COSTS</strong></h3>

<p>You are responsible for the shipping costs associated with the delivery of the Products you purchase on the Site as specified on your order confirmation.</p>

<p>Terms and / or Agreement, the Parties agree that the Terms and Conditions in force on the date of receipt of the consent specified will be applied.</p>

<h3><strong>DELIVERY AND SHIPMENTS</strong></h3>

<p>Your order will be fulfilled by the delivery date set out in the Dispatch Confirmation or, if no delivery date is specified, within 30 days after the date of the Dispatch Confirmation, unless there are exceptional circumstances. Delivery is deemed complete upon receipt of the Products to the address you specified in your order. If your delivery address is geographically remote, for example certain outlying islands or other isolated locations; it is possible that we may not be able to deliver there. If that is the case, we will notify you before we accept your order. We reserve the right not to deliver to any place that is prohibited by govt. Products comprised within the same order cannot be delivered to different addresses. We would like to remind you that all Products purchased are intended for use in the country they were ordered from. If, however, you decide to take the items to another country, you will be responsible for adhering to both the exportation legislation of the order country and the importation legislation of the destination country.</p>

<p>Please note that the courier may require deliveries to be signed for.</p>

<h3><strong>PRE-ORDERS</strong></h3>

<p>All Products are available to ship unless it is a pre-order item. All pre-order Products have an estimated ship date that indicates the date that the Product will be shipped out from our warehouse, but these Products may arrive sooner or later than specified. Estimated shipping date is published online. Drippz is not liable for delays, cancellations or changes to pre-ordered items made during production. If a pre-ordered item will not be shipped out by the estimated shipping date, the customer will be notified and, at the customer&rsquo;s election, either refunded in full or provided with an updated estimated shipping date. If an order includes both available and pre-ordered items, at least two separate deliveries will be most likely made to the customer.</p>

<h3><strong>PRICE AND PAYMENT</strong></h3>

<p>Prices may change without notice. While we try to ensure that all prices on our Website are accurate, errors may occur. Changes will not affect orders in respect of which we have already sent you a Dispatch Confirmation. The Site contains a large number of Products and it is always possible that, despite our best efforts, some of the Products listed on the Site may be incorrectly priced. We will normally verify prices as part of our dispatch procedures so that, where a Product&#39;s correct price is less than our stated price, we will charge you the lower amount. If a Product&rsquo;s correct price is higher than the price stated on the Site, we will normally, at our discretion, either contact you for instructions before dispatching the Product, or reject your order and notify you of such rejection. Prices include GST but exclude delivery costs, which will be automatically added (at the cost shown) to the total amount due when you view the items in your shopping basket and have selected your chosen different delivery method.</p>

<p>Payment for all orders must be made by credit, debit card or UPI on the checkout page. We accept payment by most major credit, debit card or UPI. You should be aware that online payment transactions are subject to validation checks by your card issuer and we are not responsible if your card issuer declines to authorise payment for any reason. We also carry out a standard pre-authorisation check on your payment card, and Products will not be dispatched until this pre-authorisation check has been completed. From time to time we may run promotions where we issue discount codes. These can be used in part-payment of the price of Products ordered online, subject to the terms and conditions under which they were issued (as indicated in our relevant promotion from which you got the code). Discount codes can only be used once, and only during the period of validity stated and only in respect of the relevant Products stated. Discount codes cannot be redeemed for cash.</p>

<h3><strong>RETURNS AND REFUNDS</strong></h3>

<p>Please refer to our&nbsp;<a href="#">Return &amp; Return Policy</a>&nbsp;which forms an integral part of these Terms and Conditions.</p>

<h3><strong>PRIVACY</strong></h3>

<p>We recommend that you read our Privacy Policy which explains our online privacy practices.</p>

<h3><strong>INTELLECTUAL PROPERTY</strong></h3>

<p>All content included on the Site, such as works, images, pictures, dialogues, music, sounds, videos, documents, drawings, figures, logos, menus, web pages, graphics, colors, schemes, tools, fonts, designs, diagrams, layouts, methods, processes, functions and software (collectively, the &quot;Content&quot;), is the property of Drippz and is protected by national and international copyright and other intellectual property laws. You may not reproduce, publish, distribute, display, modify, create derivative work from, or exploit in any way, in whole or in part, the Content without the prior express written consent of Drippz has the case may be. Drippz shall have the exclusive right to authorize or prohibit in their sole discretion any reproduction, publication, distribution, display, modification, creation of derivative work from, or exploitation in any way of, in whole or in part, the Content. Drippz shall have the right, at any time, to claim the authorship of any Content posted on the Site and to object to any use, distortion or other modification of such Content. Any reproduction, publication, distribution, display, modification, creation of derivative work from, or exploitation in any way of, the Content expressly authorized in writing by Drippz shall be carried out by you for lawful purposes only and in compliance with all applicable laws.</p>

<h3><strong>LICENSEE AND SITE ACCESS</strong></h3>

<p>The viewing, printing or downloading of any Content from the Site grants you only a limited, nonexclusive and nontransferable license for use solely by you for your own personal use and not for republication, distribution, assignment, sublicense, sale, preparation of derivative works or other use. No part of any Content may be reproduced in any form or incorporated into any information system, electronic or mechanical, other than for your personal use (but not for resale or redistribution). You will be solely responsible for all damages and other harm resulting from your use of the Site and the Content. Drippz shall not be deemed liable for any use of the Site and the Content made by you in violation of any applicable laws and regulations and these Terms and Conditions.</p>

<h3><strong>EXTERNAL LINKS</strong></h3>

<p>The Site may, from time to time, include links to external sites &ldquo;Third Party Websites&rdquo;, which may include links to third party offers and promotions. We include these to provide you with access to information, products or services that you may find useful or interesting. We are not responsible for the content of Third Party Websites or for anything provided by them and do not guarantee that they will be continuously available. The fact that we include links to such external sites does not imply any endorsement of our association with their operators or promoters.</p>

<p><br />
YOU AGREE THAT YOUR USE OF ANY THIRD PARTY WEBSITE IS AT YOUR SOLE RISK AND WITHOUT WARRANTIES OF ANY KIND BY THE PROVIDER, EXPRESSED, IMPLIED OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF TITLE, FITNESS FOR A PARTICULAR PURPOSE, MERCHANTABILITY OR NONINFRINGEMENT. UNDER NO CIRCUMSTANCES ARE THE PROVIDER AND/OR OC LIABLE FOR DAMAGES ARISING FROM ANY TRANSACTION BETWEEN YOU AND ANY THIRD PARTY WEBSITE OR FOR ANY INFORMATION APPEARING ON THIRD PARTY WEBSITES.</p>

<h3><strong>MISCELLANEOUS</strong></h3>

<p>You may not transfer or assign any or all of your rights or obligations under any Contract.&nbsp;</p>

<p>All notices given by you to us shall be given in writing to the address set out at the end of these Terms and Conditions. We may give notice to you at either the email or postal address you provide to us when placing an order.</p>

<p>If we fail to enforce any of our rights, that does not result in a waiver of that right.&nbsp;</p>

<p>If any provision of these Terms and Conditions is determined to be invalid, illegal or unenforceable, the remaining provisions of these General Terms and Conditions of Sale remain in full force to the extent permitted by law.</p>

<p>These Terms and Conditions and any document expressly referred to in them represent the entire agreement between you and us in relation to the subject matter of any Contract. We are required by law to advise you that Contracts may be concluded in the English language only and that no public filing requirements apply.</p>

<p>All matters arising out of or relating to these Terms and Conditions, including, without limitation, their validity, interpretation, construction, performance, and enforcement shall be governed by&nbsp; law of India. The Courts of India shall have the exclusive jurisdiction to settle all legal proceedings arising out of or in connection with these Terms and Conditions.</p>

<h3><strong>DISCLAIMERS AND LIMITATIONS OF LIABILITY</strong></h3>

<p>THE PROVIDER MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE PRODUCTS INCLUDED IN THE drippz.in SITE NOR AS TO THE MERCHANDISE BEING SOLD TO YOU. TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, THE PROVIDER DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT, AND THERE ARE NO WARRANTIES, EXPRESS OR IMPLIED, WHICH EXTEND BEYOND THE DESCRIPTION OF THE MERCHANDISE CONTAINED ON OUR ORDER CONFIRMATION. THE PROVIDER WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THE SITE, INCLUDING BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE AND CONSEQUENTIAL DAMAGES</p>

<p>CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.</p>

<h3><strong>PRIVACY</strong></h3>

<p>The Terms and Conditions of the Privacy Policy govern the processing of all personal data collected from you in connection with your purchase of Products through the Site.</p>

<h3><strong>FORCE MAJEURE</strong></h3>

<p>Provider shall not be liable for any delay or failure in performance caused by circumstances beyond its reasonable control.</p>

<h3><strong>CONTACTING US</strong></h3>

<p>Please submit any questions you have about these Terms and Conditions or an order you have placed or ordering in general by email to&nbsp;info@drippz.in &nbsp;or write to us at:</p>

<p>Vill-Panshila,P.O-Deulia,P.S-Kolaghat,Dist.-Purba Medinipur,Pin-721154,State-West Bengal,India.</p>

<p>&nbsp;</p>


       </div>
    </div>
</div>