<app-header class="header-home {{headerClass}}"></app-header>

<div class="home-page">
  <div #hfeat></div>
  <section class="home-hero">
    <swiper [navigation]="true" [pagination]="false" [loop]="true" [lazy]="true" [autoplay]="false" class="flex position-center">
      <ng-container>
        <ng-template swiperSlide>
          <a href="/search" class="slider-item">
            <img class="" src="../assets/images/ban3.jpg" alt="www.drippz.in">
          </a>
          <div class="flex column position-center description">
            <div class="txt">Out Now</div>
            <a href="/search" class="flex position-center shop-now">Shop now</a>
          </div>
        </ng-template>
        <ng-template swiperSlide>
          <a href="/product/all-out-quotation-t-shirt-30648?vkey=184" class="slider-item">
            <img class="" src="../assets/images/ban2.jpg" alt="www.drippz.in">
          </a>
         
          <div class="flex column position-center description">
            <div class="txt">All Out Quotation Oversize T-Shirt</div>
            <a href="/product/all-out-quotation-t-shirt-30648?vkey=184" class="flex position-center shop-now">Shop now</a>
          </div>
        </ng-template>
        <ng-template swiperSlide>
            <a href="/product/all-i-need-is-money-quoted-oversize-t-shirt-black-30636?vkey=172" class="slider-item">
              <img class="" src="../assets/images/ban4.jpg" alt="www.drippz.in">
            </a>
            <div class="flex column position-center description">
              <div class="txt">All I Need is Money Quoted Oversize T-shirt</div>
              <a href="/product/all-i-need-is-money-quoted-oversize-t-shirt-black-30636?vkey=172" class="flex position-center shop-now">Shop now</a>
            </div>
          </ng-template>
        <ng-template swiperSlide>
            <a href="/product/alyx-the-dark-enigma-t-shirt-white-30594?vkey=132" class="slider-item">
              <img class="" src="../assets/images/ban1.jpg" alt="www.drippz.in">
            </a>
            <div class="flex column position-center description">
              <div class="txt">ALYX - The Dark Enigma T-shirt</div>
              <a href="/product/alyx-the-dark-enigma-t-shirt-white-30594?vkey=132" class="flex position-center shop-now">Shop now</a>
            </div>
          </ng-template>
      </ng-container>
    </swiper>
  </section>
  <div class="flex position-center home-announcement2">Happy Independence Day | Use Coupon IND50 to get 50% OFF | 7 Days Free Return</div>

  <section class="home-section home-products trending">
    <div class="container">
        <div class="flex position-center heading">
            <span>New Arrivals</span>
        </div>
        <div class="product-box">
            <div class="product-item">
                <div class="content">
                    <a href="/product/all-out-quotation-oversize-t-shirt-black-30637?vkey=173" class="media skeleton">
                        <img class="img1" src="../assets/images/hp1.jpg" alt="www.drippz.in">
                        <img class="img2" src="../assets/images/hp2.jpg" alt="www.drippz.in">
                        <div class="flex sold-out-banner animated">
                            <div class="flex banner-container">
                              <div class="flex allign-middle text-message">
                                <p>SOLD OUT !</p>
                                <p>SOLD OUT !</p>
                                <p>SOLD OUT !</p>
                                <p>SOLD OUT !</p>
                              </div>
                              <div class="flex allign-middle text-message">
                                <p>SOLD OUT !</p>
                                <p>SOLD OUT !</p>
                                <p>SOLD OUT !</p>
                                <p>SOLD OUT !</p>
                              </div>
                            </div>
                        </div>
                    </a>
                    <a class="wishlist-trigger">
                        <svg viewBox="0 0 512 512">
                            <path d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
                        </svg>
                    </a>
                    <div class="details">
                        <a class="title">All Out quotation Oversize Tshirt (Black)</a>
                        <div class="pricing">
                            <span class="old">₹1399</span>
                            <span class="new">₹1199</span>
                            <span class="discount">-14%</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="product-item">
                <div class="content">
                    <a href="/product/fun-mode-on-oversize-t-shirt-grey-30644?vkey=180" class="media skeleton">
                        <img class="img1" src="../assets/images/hp3.jpg" alt="www.drippz.in">
                        <img class="img2" src="../assets/images/hp4.jpg" alt="www.drippz.in">
                        <div class="flex sold-out-banner animated">
                            <div class="flex banner-container">
                              <div class="flex allign-middle text-message">
                                <p>SOLD OUT !</p>
                                <p>SOLD OUT !</p>
                                <p>SOLD OUT !</p>
                                <p>SOLD OUT !</p>
                              </div>
                              <div class="flex allign-middle text-message">
                                <p>SOLD OUT !</p>
                                <p>SOLD OUT !</p>
                                <p>SOLD OUT !</p>
                                <p>SOLD OUT !</p>
                              </div>
                            </div>
                        </div>
                    </a>
                    <a class="wishlist-trigger">
                        <svg viewBox="0 0 512 512">
                            <path d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
                        </svg>
                    </a>
                    <div class="details">
                        <a class="title">Fun Mode On Oversize Tshirt (Grey)</a>
                        <div class="pricing">
                            <span class="old">₹1249</span>
                            <span class="new">₹1099</span>
                            <span class="discount">-3%</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="product-item">
                <div class="content">
                    <a href="/product/don-t-quit-oversize-t-shirt-black-30640?vkey=176" class="media skeleton">
                        <img class="img1" src="../assets/images/hp5.jpg" alt="www.drippz.in">
                        <img class="img2" src="../assets/images/hp6.jpg" alt="www.drippz.in">
                        <div class="flex sold-out-banner animated">
                            <div class="flex banner-container">
                              <div class="flex allign-middle text-message">
                                <p>SOLD OUT !</p>
                                <p>SOLD OUT !</p>
                                <p>SOLD OUT !</p>
                                <p>SOLD OUT !</p>
                              </div>
                              <div class="flex allign-middle text-message">
                                <p>SOLD OUT !</p>
                                <p>SOLD OUT !</p>
                                <p>SOLD OUT !</p>
                                <p>SOLD OUT !</p>
                              </div>
                            </div>
                        </div>
                    </a>
                    <a class="wishlist-trigger">
                        <svg viewBox="0 0 512 512">
                            <path d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
                        </svg>
                    </a>
                    <div class="details">
                        <a class="title">Dont Quit Oversize T-shirt (Black)</a>
                        <div class="pricing">
                            <span class="old">₹1449</span>
                            <span class="new">₹1399</span>
                            <span class="discount">-4%</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="product-item">
                <div class="content">
                    <a href="/product/beat-the-heat-with-sun-oversize-t-shirt-grey-30638?vkey=174" class="media skeleton">
                        <img class="img1" src="../assets/images/hp7.jpg" alt="www.drippz.in">
                        <img class="img2" src="../assets/images/hp8.jpg" alt="www.drippz.in">
                        <div class="flex sold-out-banner animated">
                            <div class="flex banner-container">
                              <div class="flex allign-middle text-message">
                                <p>SOLD OUT !</p>
                                <p>SOLD OUT !</p>
                                <p>SOLD OUT !</p>
                                <p>SOLD OUT !</p>
                              </div>
                              <div class="flex allign-middle text-message">
                                <p>SOLD OUT !</p>
                                <p>SOLD OUT !</p>
                                <p>SOLD OUT !</p>
                                <p>SOLD OUT !</p>
                              </div>
                            </div>
                        </div>
                    </a>
                    <a class="wishlist-trigger">
                        <svg viewBox="0 0 512 512">
                            <path d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
                        </svg>
                    </a>
                    <div class="details">
                        <a class="title">Beat The Heat with Sun Oversize T-shirt (Grey)</a>
                        <div class="pricing">
                            <span class="old">₹1349</span>
                            <span class="new">₹1299</span>
                            <span class="discount">-50%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="flex home-collection home-featured-img set-1">
    <a href="/product/red-ghost-motif-oversize-t-shirt-red-30649?vkey=185" class="flex position-center content">
        <img src="../assets/images/ftcoll.jpg" alt="www.drippz.in">
        <p class="description">
            <span class="text">Red Ghost Motif Oversize T-Shirt
                Out Now</span>
            <span class="shop-button">Shop Now</span>
        </p>
    </a>
</section>
<a href="/category/oversize-t-shirt" class="home-video">
    <video autoplay="" muted="" loop="" id="myVideo">
        <source src="../assets/images/hvideo2.mp4" type="video/mp4">
        Your browser does not support HTML5 video.
    </video>
    <p class="description">
        <span class="shop-button">Shop Now</span>
    </p>
</a>
  <section class="flex home-collection home-featured-img set-1">
      <a href="/category/tie-dye-t-shirt" class="flex position-center content">
          <img src="../assets/images/ftcoll2.jpg" alt="www.drippz.in">
          <p class="description">
              <span class="text">Coloured Patches Collection.</span>
              <span class="shop-button">Shop Now</span>
          </p>
      </a>
  </section>
  <section class="home-section home-products trending">
      <div class="container">
          <div class="flex position-center heading">
              <span>Trending Products</span>
          </div>
          <div class="product-box">
              <div class="product-item">
                  <div class="content">
                      <a href="/product/happiness-oversize-tshirt-black-30645?vkey=181" class="media skeleton">
                          <img class="img1" src="../assets/images/hp9.jpg" alt="www.drippz.in">
                          <img class="img2" src="../assets/images/hp10.jpg" alt="www.drippz.in">
                          <div class="flex sold-out-banner animated">
                              <div class="flex banner-container">
                                <div class="flex allign-middle text-message">
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                </div>
                                <div class="flex allign-middle text-message">
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                </div>
                              </div>
                          </div>
                      </a>
                      <a class="wishlist-trigger">
                          <svg viewBox="0 0 512 512">
                              <path d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
                          </svg>
                      </a>
                      <div class="details">
                          <a class="title">Happyness Oversize T-shirt (Black)</a>
                          <div class="pricing">
                              <span class="old">₹1249</span>
                              <span class="new">₹1099</span>
                              <span class="discount">-12%</span>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="product-item sold-out">
                  <div class="content">
                      <a href="/product/nature-s-ballet-t-shirt-30626?vkey=162" class="media skeleton">
                          <img class="img1" src="../assets/images/hp11.jpg" alt="www.drippz.in">
                          <img class="img2" src="../assets/images/hp12.jpg" alt="www.drippz.in">
                          <div class="flex sold-out-banner animated">
                              <div class="flex banner-container">
                                <div class="flex allign-middle text-message">
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                </div>
                                <div class="flex allign-middle text-message">
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                </div>
                              </div>
                          </div>
                      </a>
                      <a class="wishlist-trigger">
                          <svg viewBox="0 0 512 512">
                              <path d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
                          </svg>
                      </a>
                      <div class="details">
                          <a class="title">Natures Ballet T-Shirt</a>
                          <div class="pricing">
                              <span class="old">₹1099</span>
                              <span class="new">₹599</span>
                              <span class="discount">-45%</span>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="product-item">
                  <div class="content">
                      <a href="/product/bitterlife-oversize-t-shirt-grey-30639?vkey=175" class="media skeleton">
                          <img class="img1" src="../assets/images/hp13.jpg" alt="www.drippz.in">
                          <img class="img2" src="../assets/images/hp14.jpg" alt="www.drippz.in">
                          <div class="flex sold-out-banner animated">
                              <div class="flex banner-container">
                                <div class="flex allign-middle text-message">
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                </div>
                                <div class="flex allign-middle text-message">
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                </div>
                              </div>
                          </div>
                      </a>
                      <a class="wishlist-trigger">
                          <svg viewBox="0 0 512 512">
                              <path d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
                          </svg>
                      </a>
                      <div class="details">
                          <a class="title">Bitterlife Oversize Tshirt (Grey)</a>
                          <div class="pricing">
                              <span class="old">₹1249</span>
                              <span class="new">₹1199</span>
                              <span class="discount">-4%</span>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="product-item">
                  <div class="content">
                      <a href="/product/live-free-oversize-t-shirt-black-30647?vkey=183" class="media skeleton">
                          <img class="img1" src="../assets/images/hp15.jpg" alt="www.drippz.in">
                          <img class="img2" src="../assets/images/hp16.jpg" alt="www.drippz.in">
                          <div class="flex sold-out-banner animated">
                              <div class="flex banner-container">
                                <div class="flex allign-middle text-message">
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                </div>
                                <div class="flex allign-middle text-message">
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                </div>
                              </div>
                          </div>
                      </a>
                      <a class="wishlist-trigger">
                          <svg viewBox="0 0 512 512">
                              <path d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
                          </svg>
                      </a>
                      <div class="details">
                          <a class="title">Live Free Oversize T-shirt (Black)</a>
                          <div class="pricing">
                              <span class="old">₹1249</span>
                              <span class="new">₹1099</span>
                              <span class="discount">-12%</span>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="product-item">
                  <div class="content">
                      <a href="/product/smile-with-vibes-t-shirt-white-30601?vkey=138" class="media skeleton">
                          <img class="img1" src="../assets/images/hp17.jpg" alt="www.drippz.in">
                          <img class="img2" src="../assets/images/hp18.jpg" alt="www.drippz.in">
                          <div class="flex sold-out-banner animated">
                              <div class="flex banner-container">
                                <div class="flex allign-middle text-message">
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                </div>
                                <div class="flex allign-middle text-message">
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                </div>
                              </div>
                          </div>
                      </a>
                      <a class="wishlist-trigger">
                          <svg viewBox="0 0 512 512">
                              <path d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
                          </svg>
                      </a>
                      <div class="details">
                          <a class="title">Smile with Vibes T-Shirt (White)</a>
                          <div class="pricing">
                              <span class="old">₹1049</span>
                              <span class="new">₹799</span>
                              <span class="discount">-23%</span>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="product-item">
                  <div class="content">
                      <a href="/product/hustle-oversize-t-shirt-black-30646?vkey=182" class="media skeleton">
                          <img class="img1" src="../assets/images/hp19.jpg" alt="www.drippz.in">
                          <img class="img2" src="../assets/images/hp20.jpg" alt="www.drippz.in">
                          <div class="flex sold-out-banner animated">
                              <div class="flex banner-container">
                                <div class="flex allign-middle text-message">
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                </div>
                                <div class="flex allign-middle text-message">
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                </div>
                              </div>
                          </div>
                      </a>
                      <a class="wishlist-trigger">
                          <svg viewBox="0 0 512 512">
                              <path d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
                          </svg>
                      </a>
                      <div class="details">
                          <a class="title">Hustle Oversize T-shirt (Black)</a>
                          <div class="pricing">
                              <span class="old">₹1349</span>
                              <span class="new">₹1299</span>
                              <span class="discount">-4%</span>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="product-item">
                  <div class="content">
                      <a href="/product/nature-s-elegance-in-vibrant-floral-oversize-t-shirt-black-30643?vkey=179" class="media skeleton">
                          <img class="img1" src="../assets/images/hp21.jpg" alt="www.drippz.in">
                          <img class="img2" src="../assets/images/hp22.jpg" alt="www.drippz.in">
                          <div class="flex sold-out-banner animated">
                              <div class="flex banner-container">
                                <div class="flex allign-middle text-message">
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                </div>
                                <div class="flex allign-middle text-message">
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                </div>
                              </div>
                          </div>
                      </a>
                      <a class="wishlist-trigger">
                          <svg viewBox="0 0 512 512">
                              <path d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
                          </svg>
                      </a>
                      <div class="details">
                          <a class="title">Natures Elegance in Vibrant Floral Oversize T-shirt (Black)</a>
                          <div class="pricing">
                              <span class="old">₹1499</span>
                              <span class="new">₹999</span>
                              <span class="discount">-33%</span>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="product-item sold-out">
                  <div class="content">
                      <a href="/product/unleashed-abstract-t-shirt-30605?vkey=142" class="media skeleton">
                          <img class="img1" src="../assets/images/hp23.jpg" alt="www.drippz.in">
                          <img class="img2" src="../assets/images/hp24.jpg" alt="www.drippz.in">
                          <div class="flex sold-out-banner animated">
                              <div class="flex banner-container">
                                <div class="flex allign-middle text-message">
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                </div>
                                <div class="flex allign-middle text-message">
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                  <p>SOLD OUT !</p>
                                </div>
                              </div>
                          </div>
                      </a>
                      <a class="wishlist-trigger">
                          <svg viewBox="0 0 512 512">
                              <path d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
                          </svg>
                      </a>
                      <div class="details">
                          <a class="title">Unleashed Abstract T-Shirt</a>
                          <div class="pricing">
                              <span class="old">₹999</span>
                              <span class="new">₹599</span>
                              <span class="discount">-40%</span>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <a href="/search" class="viewall-button">VIEW ALL</a>
      </div>
  </section>

  <section class="home-branding">
    <div class="flex container">
        <div class="flex column item">
            <img src="../assets/images/b1.png" alt="Free Delivery">
            <span>Premium Quality</span>
        </div>
        <div class="flex column item">
            <img src="../assets/images/b3.png" alt="Secure Payment">
            <span>Secure Payment</span>
        </div>
        <div class="flex column item">
            <img src="../assets/images/b4.png" alt="Easy Return">
            <span>Free Delivery</span>
        </div>
        
        <div class="flex column item">
            <img src="../assets/images/b2.png" alt="Premium Quality">
            <span>Cash on Delivery</span>
        </div>
        <div class="flex column item">
          <img src="../assets/images/b6.png" alt="Easy Return">
          <span>Easy Return</span>
        </div>
        <div class="flex column item">
            <img src="../assets/images/b5.png" alt="Free Delivery">
            <span>Made in India</span>
        </div>
    </div>
</section>


</div>